import React from "react";

const FourArrowsIcon = ({ direction }) => {
  switch (direction) {
    case "Up":
      return (
        <svg
          width="88px"
          height="88px"
          viewBox="0 0 88 88"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
              id="filter_1"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dx="0" dy="2" />
              <feGaussianBlur stdDeviation="2" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07058824 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect0_dropShadow"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect0_dropShadow"
                result="shape"
              />
            </filter>
          </defs>
          <path
            d="M38 76C58.9868 76 76 58.9868 76 38C76 17.0132 58.9868 0 38 0C17.0132 0 0 17.0132 0 38C0 58.9868 17.0132 76 38 76Z"
            transform="translate(6 4)"
            id="Oval-Copy-14"
            fill="#FFFFFF"
            fillRule="evenodd"
            stroke="none"
            filter="url(#filter_1)"
          />
          <g id="biarrow-right-short-copy-3" transform="translate(16 16)">
            <path
              d="M0 0L56 0L56 56L0 56L0 0Z"
              id="ViewBox"
              fill="none"
              fillRule="evenodd"
              stroke="none"
            />
            <g
              id="Group"
              transform="matrix(-3.206726E-06 -1 1 -3.206726E-06 15 42.00122)"
            >
              <path
                d="M0.514064 0.545487C0.842338 0.196265 1.28807 0 1.75291 0C2.21775 0 2.66348 0.196265 2.99175 0.545487L13.4904 11.6859C13.8195 12.0343 14.0045 12.5072 14.0045 13.0005C14.0045 13.4937 13.8195 13.9667 13.4904 14.3151L2.99175 25.4555C2.30756 26.1815 1.19826 26.1815 0.514064 25.4555C-0.170131 24.7295 -0.170131 23.5524 0.514064 22.8263L9.7774 13.0005L0.514064 3.17463C0.184959 2.82629 0 2.35331 0 1.86006C0 1.36681 0.184959 0.893827 0.514064 0.545487Z"
                transform="translate(13.995605 0)"
                id="Path"
                fill="#23b6ff"
                stroke="none"
              />
              <path
                d="M0 1.85674C0 0.83129 0.783403 0 1.74978 0L24.4969 0C25.4633 0 26.2467 0.83129 26.2467 1.85674C26.2467 2.88219 25.4633 3.71348 24.4969 3.71348L1.74978 3.71348C0.783403 3.71348 0 2.88219 0 1.85674Z"
                transform="translate(-0.00024414062 11.143555)"
                id="Path"
                fill="#23b6ff"
                stroke="none"
              />
            </g>
          </g>
        </svg>
      );
    case "Down":
      return (
        <svg
          width="88px"
          height="88px"
          viewBox="0 0 88 88"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
              id="filter_1"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dx="0" dy="2" />
              <feGaussianBlur stdDeviation="2" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07058824 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect0_dropShadow"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect0_dropShadow"
                result="shape"
              />
            </filter>
          </defs>
          <path
            d="M38 76C58.9868 76 76 58.9868 76 38C76 17.0132 58.9868 0 38 0C17.0132 0 0 17.0132 0 38C0 58.9868 17.0132 76 38 76Z"
            transform="translate(6 4)"
            id="Oval-Copy-14"
            fill="#FFFFFF"
            fillRule="evenodd"
            stroke="none"
            filter="url(#filter_1)"
          />
          <g id="biarrow-right-short-copy-3" transform="translate(16 16)">
            <path
              d="M0 0L56 0L56 56L0 56L0 0Z"
              id="ViewBox"
              fill="none"
              fillRule="evenodd"
              stroke="none"
            />
            <g
              id="Group"
              transform="matrix(1.1483816E-06 1 -1 1.1483816E-06 41 14.000977)"
            >
              <path
                d="M0.514064 0.545487C0.842338 0.196265 1.28807 0 1.75291 0C2.21775 0 2.66348 0.196265 2.99175 0.545487L13.4904 11.6859C13.8195 12.0343 14.0045 12.5072 14.0045 13.0005C14.0045 13.4937 13.8195 13.9667 13.4904 14.3151L2.99175 25.4555C2.30756 26.1815 1.19826 26.1815 0.514064 25.4555C-0.170131 24.7295 -0.170131 23.5524 0.514064 22.8263L9.7774 13.0005L0.514064 3.17463C0.184959 2.82629 0 2.35331 0 1.86006C0 1.36681 0.184959 0.893827 0.514064 0.545487Z"
                transform="translate(13.995117 0)"
                id="Path"
                fill="#23b6ff"
                stroke="none"
              />
              <path
                d="M0 1.85674C0 0.83129 0.783403 0 1.74978 0L24.4969 0C25.4633 0 26.2467 0.83129 26.2467 1.85674C26.2467 2.88219 25.4633 3.71348 24.4969 3.71348L1.74978 3.71348C0.783403 3.71348 0 2.88219 0 1.85674Z"
                transform="translate(0 11.143555)"
                id="Path"
                fill="#23b6ff"
                stroke="none"
              />
            </g>
          </g>
        </svg>
      );
    case "Left":
      return (
        <svg
          width="88px"
          height="88px"
          viewBox="0 0 88 88"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
              id="filter_1"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dx="0" dy="2" />
              <feGaussianBlur stdDeviation="2" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07058824 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect0_dropShadow"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect0_dropShadow"
                result="shape"
              />
            </filter>
          </defs>
          <path
            d="M38 76C58.9868 76 76 58.9868 76 38C76 17.0132 58.9868 0 38 0C17.0132 0 0 17.0132 0 38C0 58.9868 17.0132 76 38 76Z"
            transform="translate(6 4)"
            id="Oval-Copy-14"
            fill="#FFFFFF"
            fillRule="evenodd"
            stroke="none"
            filter="url(#filter_1)"
          />
          <g id="biarrow-right-short-copy-3" transform="translate(16 16)">
            <path
              d="M0 0L56 0L56 56L0 56L0 0Z"
              id="ViewBox"
              fill="none"
              fillRule="evenodd"
              stroke="none"
            />
            <g
              id="Group"
              transform="matrix(-1 2.2967631E-06 -2.2967631E-06 -1 42.003906 40.251953)"
            >
              <path
                d="M0.514128 0.514128C0.842443 0.184982 1.28823 0 1.75313 0C2.21802 0 2.66381 0.184982 2.99213 0.514128L13.4921 11.0141C13.8213 11.3424 14.0063 11.7882 14.0063 12.2531C14.0063 12.718 13.8213 13.1638 13.4921 13.4921L2.99213 23.9921C2.30785 24.6764 1.19841 24.6764 0.514128 23.9921C-0.170153 23.3078 -0.170153 22.1984 0.514128 21.5141L9.77863 12.2531L0.514128 2.99213C0.184982 2.66381 0 2.21802 0 1.75313C0 1.28823 0.184982 0.842443 0.514128 0.514128Z"
                transform="translate(13.99707 0)"
                id="Path"
                fill="#23b6ff"
                stroke="none"
              />
              <path
                d="M0 1.75C0 0.783502 0.783502 0 1.75 0L24.5 0C25.4665 0 26.25 0.783502 26.25 1.75C26.25 2.7165 25.4665 3.5 24.5 3.5L1.75 3.5C0.783502 3.5 0 2.7165 0 1.75Z"
                transform="translate(0 10.50293)"
                id="Path"
                fill="#23b6ff"
                stroke="none"
              />
            </g>
          </g>
        </svg>
      );
    case "Right":
      return (
        <svg
          width="88px"
          height="88px"
          viewBox="0 0 88 88"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <filter
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
              id="filter_1"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dx="0" dy="2" />
              <feGaussianBlur stdDeviation="2" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07058824 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect0_dropShadow"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect0_dropShadow"
                result="shape"
              />
            </filter>
          </defs>
          <path
            d="M38 76C58.9868 76 76 58.9868 76 38C76 17.0132 58.9868 0 38 0C17.0132 0 0 17.0132 0 38C0 58.9868 17.0132 76 38 76Z"
            transform="translate(6 4)"
            id="Oval-Copy-14"
            fill="#FFFFFF"
            fillRule="evenodd"
            stroke="none"
            filter="url(#filter_1)"
          />
          <g id="biarrow-right-short-copy-3" transform="translate(16 16)">
            <path
              d="M0 0L56 0L56 56L0 56L0 0Z"
              id="ViewBox"
              fill="none"
              fillRule="evenodd"
              stroke="none"
            />
            <g id="Group" transform="translate(14 15)">
              <path
                d="M0.514064 0.545487C0.842338 0.196265 1.28807 0 1.75291 0C2.21775 0 2.66348 0.196265 2.99175 0.545487L13.4904 11.6859C13.8195 12.0343 14.0045 12.5072 14.0045 13.0005C14.0045 13.4937 13.8195 13.9667 13.4904 14.3151L2.99175 25.4555C2.30756 26.1815 1.19826 26.1815 0.514064 25.4555C-0.170131 24.7295 -0.170131 23.5524 0.514064 22.8263L9.7774 13.0005L0.514064 3.17463C0.184959 2.82629 0 2.35331 0 1.86006C0 1.36681 0.184959 0.893827 0.514064 0.545487Z"
                transform="translate(13.995117 0)"
                id="Path"
                fill="#23b6ff"
                stroke="none"
              />
              <path
                d="M0 1.85674C0 0.83129 0.783403 0 1.74978 0L24.4969 0C25.4633 0 26.2467 0.83129 26.2467 1.85674C26.2467 2.88219 25.4633 3.71348 24.4969 3.71348L1.74978 3.71348C0.783403 3.71348 0 2.88219 0 1.85674Z"
                transform="translate(0 11.143555)"
                id="Path"
                fill="#23b6ff"
                stroke="none"
              />
            </g>
          </g>
        </svg>
      );
    default:
      return null;
  }
};
export default FourArrowsIcon;
