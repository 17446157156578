import React, { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import Header from "../components/header/header.js";
import Image from "../components/image/image.js";
import ReadMore from "../components/readMore/readMore.js";
import FourArrows from "../components/fourArrows/fourArrowsIcon.js";

import "../assets/styles/pages/_our-integrations.scss";

const OurIntegration = () => {

  const theme = useContext(ThemeContext);

  return (
    <>
      <Header
        title="Le Nostre Integrazioni"
        metaDescription="La nostra suite dedicata al settore hospitality è un potente strumento che ti permetterà di prenderti cura del tuo cliente in ogni momento."
      />
      <div className="container ourIntegrations">
        <div className="columns">
          <div className="column">
            <h2>La Suite Hospitality</h2>
          </div>
          <div className="column">
            <p className="description">
              La nostra suite dedicata al settore hospitality è un potente
              strumento che ti permetterà di prenderti cura del tuo cliente in
              ogni momento, assicurandogli un’assistenza trasversale.
              <br />
              <br />
              <br />
              Il sistema di prenotazione è rapido e garantisce di evitare
              l’overbooking, oltre ad assicurare il checkIN online.
              <br />
              <br />
              <br />
              L’utente potrà godere di una navigazione sicura, e al tempo
              stesso, tu sarai in grado di monitorare l’umore del tuo ospite e
              di far fronte alle sue esigenze.
              <br />
              <br />
              <br />
              Potrai creare il tuo database di contatti ed attivare attività
              promozionali ed azioni di marketing in base a target profilati.
              <br />
              <br />
              <br />
              Le recensioni positive saranno assicurate, insieme ad un aumento
              della brand reputation!
            </p>
            <div className="section" />
            <ReadMore to="/hospitality" text="Scopri di più" />
          </div>
        </div>
        <div className="section" />
        <div className="columns">
          <div className="column ">
            <Image className="floatA" filename="do-not-disturb.png" alt="" />
            <div className="section" />
            <img src="https://static.acmeproduzioni.it/bookatme/logo.svg" alt="Logo Connect@You" />
          </div>
          <div className="column">
          <div className="arrow">
            <FourArrows direction={theme.isMobile ? "Down" : "Right"}/>
            </div> 
          </div>
          <div className="column">
            <Image className="floatB" filename="key-wifi.png" alt="" />
            <div className="section" />
            <img src="https://static.acmeproduzioni.it/connectatyou/logo.svg" alt="Logo Book@Me" />
          </div>
          <div className="column">
          <div className="arrow">
            <FourArrows direction={theme.isMobile ? "Down" : "Right"}/>
            </div> 
          </div>
          <div className="column">
            <Image className="floatC" filename="chat-star.png" alt="" />
            <div className="section" />
            <img src="https://static.acmeproduzioni.it/eucleia/logo.svg" alt="Logo Eucleia" />
          </div>
        </div>
        <div className="section" />
        <div className="columns">
          <div className="column">
            <h2>
              Integrazioni con Software di Terze Parti
            </h2>
          </div>
          <div className="column">
            <p className="description">
              La Suite Hospitality si completa con Isidoro Software, PMS per
              hôtellerie, abbracciando esigenze di hotel, ristoranti e centri
              benessere.
              <br /> È semplice e di facile utilizzo, migliora l’affidabilità e
              la gestione della tua struttura.
              <br />
              <br />
              Le dashboard sono intuitive e consentono una pratica gestione di
              planning e prenotazioni da qualsiasi dispositivo.
              <br />
              <br />
              L’integrazione tra tutti i nostri software rende agevole la
              gestione dell’utente in ogni fase, dalla prenotazione al
              check-out.
              <br />
              <br />
              Grazie all'integrazione con 4Dem tutti i dati profilati raccolti
              tramite Connect@You, potranno essere gestiti direttamente dalle
              liste presenti all'interno del software 4Dem, semplificando la
              gestione dell'invio delle tue campagne marketing.
            </p>
          </div>
        </div>
        <div className="section" />
        <div className="columns">
          <div className="column ">
            <Image filename="isidoro.png" alt="" />
          </div>
          <div className="column ">
            <div className="arrow">
            <FourArrows direction={theme.isMobile ? "Down" : "Right"}/>
            </div>                        
          </div>
          <div className="column">
            <Image className="floatB" filename="puzzle.png" alt="" />
          </div>
          <div className="column ">
          <div className="arrow">
            <FourArrows direction={theme.isMobile ? "Up" : "Left"}/>
            </div> 
          </div>
          <div className="column">
            <Image filename="4Dem.png" alt="" />
          </div>
        </div>
      </div>
      <div className="section" />
    </>
  );
};

export default OurIntegration;
